.join-community{background-image: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697107493/d-id-front/community-bg_rv6wrd.png");background-repeat: no-repeat;background-size: cover;padding: 59px 0 43px;}
.join-community span{font: normal normal 600 40px/55px Outfit;letter-spacing: 0px;color: #08B08C;display: block;}
.join-community h2{color: #FFFFFF;margin-bottom: 40px;}
.join-community p{color: #FEFEFE;width: 689px;text-align: center;margin-bottom: 50px;}
.hvr-shutter-in-horizontal-white { display: inline-block; vertical-align: middle; -webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0); box-shadow: 0 0 1px rgba(0, 0, 0, 0); position: relative; background: #fff; -webkit-transition-property: color; transition-property: color; -webkit-transition-duration: 0.3s; transition-duration: 0.3s;}
.hvr-shutter-in-horizontal-white:before { content: ""; position: absolute; z-index: -1; top: 0; bottom: 0; left: 0; right: 0; background: #fff; -webkit-transform: scaleX(1); transform: scaleX(1); -webkit-transform-origin: 50%; transform-origin: 50%; -webkit-transition-property: transform; transition-property: transform; -webkit-transition-duration: 0.3s; transition-duration: 0.3s; -webkit-transition-timing-function: ease-out; transition-timing-function: ease-out;}
.hvr-shutter-in-horizontal-white:hover, .hvr-shutter-in-horizontal-white:focus, .hvr-shutter-in-horizontal-white:active {color: #fff;}
.hvr-shutter-in-horizontal-white:hover:before, .hvr-shutter-in-horizontal-white:focus:before, .hvr-shutter-in-horizontal-white:active:before {-webkit-transform: scaleX(0);transform: scaleX(0);}
@media (max-width: 1399px){
    .join-community h2{margin-bottom: 20px;}
}
@media (max-width: 1199px) { 
    .join-community p {width: 100%;} 
}
@media(max-width:575px){
   .join-community h2 { margin-bottom: 10px;}
}