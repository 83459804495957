#page-banner{background-color: #fff;}
#page-banner .banner-holder{ background-color: #08B08C; padding: 95px 0; border-radius: 15px;}
#page-banner .banner-heading{font-family: 'Outfit'; font-size: 60px; line-height: 63px; color: #fff; font-weight: bold;}
#page-banner .banner-sub-heading{color: #272560;}
@media(max-width:1399px){
	#page-banner .banner-heading-wrapper{ padding: 55px 0;}
	#page-banner .banner-heading { font-size: 48px; line-height: 51px;}
}
@media(max-width:405px){
	#page-banner .banner-heading-wrapper { padding: 30px 0;}
	#page-banner .banner-heading  { font-size: 28px; line-height: 31px;}
}
