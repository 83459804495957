.banner{padding: 115px 0;background: #FBFBFB 0% 0% no-repeat padding-box; overflow: hidden;}
.banner h1{font: normal normal 800 45px/50px Outfit;letter-spacing: 0px;color: #272560;margin-bottom: 64px;position: relative;}
.banner h1::after{position: absolute;content: '';background-color: #272560;width: 122px;height: 7px;bottom: -28px;left: 28px;}
.banner h1>strong{color: #08B08C;position: relative;display: block;}
.banner h1::before{position: absolute;content: '';background-color: #08B08C;width: 18px;height: 7px;bottom: -28px;left: 0;}
.banner p{font: normal normal normal 30px/44px Outfit;letter-spacing: 0px;color: #000000;}
.banner .content{margin-bottom: 80px;}
.banner-img-wrapper{min-height:745px; }
.banner-video-img{width:1069px;height:auto;position: absolute;left:-12px;top:0}
.banner-video{  width: 714px; height: 399px; position: absolute; top: 116px; left: 118px;}
.banner-video video{width: 100%;height: 100%;overflow: hidden;object-fit: cover;}


/* Media Queries */
@media(max-width:1919px){
  .banner-img-wrapper{min-height: 684px;}
  .banner-video-img{width:1020px;}
  .banner-video{left:105px;top:110px;width: 656px; height: 365px;}
}
@media (max-width: 1800px) { 
  .banner-video {width: 637px; height: 354px; left: 102px; top: 104px;} 
  .banner-video-img {width: 990px;}
}
@media (max-width: 1741px){
  .banner-video-img { width: 960px;}
  .banner-video { width: 618px; height: 340px; left: 100px; top: 102px;}
}
@media(max-width:1699px){
  .banner-video { width: 478px; height: 267px; left: 75px; top: 79px;}
  .banner h1 { font: normal normal 800 40px/44px Outfit;}
  .banner-video-img{position: inherit; width: 100%; height: auto;}
  .banner-img-wrapper {min-height: auto;}
}
@media(max-width:1613px){
   .banner-video {width: 478px; height: 266px; top: 79px; left: 75px;}
}
@media(max-width:1599px){
  .banner-video { width: 478px; height: 265px; top: 80px; left: 75px;}
}
@media (max-width: 1399px) { 
    .banner { padding: 85px 0}
   .banner-video { width: 412px; height: 228px; top: 68px; left: 61px;}
    .banner h1 {font: normal normal 800 30px/35px Outfit; margin-bottom: 44px;}
    .banner p{font: normal normal normal 14px/25px Outfit; }
    .banner h1::before{width: 15px; height: 5px; }
    .banner h1::after{width: 90px; height: 5px; left: 25px}
    .banner .content { margin-bottom: 50px;}
  }
 @media (max-width: 1199px) {  
    .banner h1 {font: normal normal 800 30px/33px Outfit;}
    .banner p{font: normal normal normal 19px/27px Outfit;}
    .banner{padding: 90px 0;}
    .banner-video{ left: 51px; top: 57px; width: 343px; height: 191px;}
 }
 @media(max-width:991px){
   .banner-video { left: 69px; top: 75px; width: 447px; height: 246px;}
 }
 @media(max-width:767px){
    .banner {padding: 32px 0;}
    .banner-video {left: 48px; top: 55px; width: 333px; height: 185px;}
 }
 @media(max-width:575px){
   .banner-video { position: relative; width: 100%; left: 0; top: 0; height: auto;}
 .banner-img-wrapper img{display: none;}
 .banner-btns-wrapper{margin-bottom: 20px;}
}
@media(max-width:392px){
  .banner h1 {font: normal normal 800 25px/30px Outfit;}
 }
@media(max-width:328px){
  .banner h1 { font: normal normal 800 22px/28px Outfit;}
}
@media(max-width:292px){
  .banner h1 { font: normal normal 800 20px/25px Outfit;}
}
 
