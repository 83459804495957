.header{background-color: #fff; width: 100%;flex: 0 0 8rem;display: flex;-webkit-box-align: center;align-items: center;padding: 20px 30px;-webkit-box-pack: justify;justify-content: space-between;overflow: hidden;}
.header .heading h2{color: #272560;font-size: 22px;font-weight: 700;line-height: 25px;margin: 0;}
.header .header-buttons .create-video-link{cursor: pointer;-webkit-user-select: none;user-select: none;vertical-align: middle;appearance: none;text-decoration: none;font-weight: 500;line-height: 20px;letter-spacing: 0.02857em;text-transform: uppercase;min-width: 64px;padding: 9px 24px;transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;color: #ffffff;box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;background: #08B08C;font-size: 14px;border-radius: 8px;display: inline-flex;border: none;align-items: center;}
.header .header-buttons .create-video-link:hover{background-color: rgba(8, 176, 140, 0.7);}
.header .header-buttons .discard-button {cursor: pointer;user-select: none;vertical-align: middle;appearance: none;text-decoration: none;font-weight: 500;line-height: 20px;letter-spacing: 0.02857em;text-transform: uppercase;min-width: 64px;transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;color: #08B08C;box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;font-size: 14px;border-radius: 8px;border: 0.1rem solid #08B08C;padding: 9px 24px;background: transparent;}
.header .header-buttons .discard-button:hover{background-color: rgba(8, 176, 140,0.2);}
.header .header-buttons button {max-height: 40px;}
/* responsive */
@media only screen and (max-width:1399px){
    .header .header-buttons .discard-button {font-size: 12px;}
    .header .header-buttons .create-video-link {font-size: 12px;}
    .header .heading h2 {font-size: 18px;line-height: 21px;}
}
@media only screen and (max-width:1199px){
    .header {padding: 20px 15px 20px 50px;}
}
@media only screen and (max-width:767px){
    .header .header-buttons .discard-button {padding: 9px 10px;}
    .header .header-buttons .create-video-link  {padding: 9px 10px;}
}
@media only screen and (max-width:575px){
    .header {flex-direction: column;}
    .header .heading {margin: 0 0 30px;}
}
@media only screen and (max-width:479px){
    .header {padding: 20px 15px 20px 30px;}
    .header .header-buttons .discard-button {font-size: 10px;}
    .header .header-buttons .create-video-link {font-size: 10px;}
    .header .header-buttons .create-video-link img {width: 12px;}
}
@media only screen and (max-width:319px){
    .header .header-buttons .create-video-link {padding: 9px 9px;}
    .header .header-buttons .discard-button {padding: 9px 9px;}
}
@media(max-width:300px){
    .header {padding: 20px 0px 20px 0px;}

}