.conversational-ai{padding: 150px 0 100px; background-color: #fff;}
.conversational-ai .video-holder{width: 956px;height: auto; border: 15px solid #F5F5F5; border-radius: 15px;}
.conversational-ai .video-holder video{width: 100%;height: 100%;object-fit: cover;}
/* Media Queries */
@media (max-width: 1500px) { 
    .conversational-ai .video-holder{width: 756px;height: auto;}
}
@media(max-width:1399px){
    .conversational-ai .video-holder {width: 678px; height: auto;}
}
@media (max-width: 1199px) { 
    .conversational-ai { padding: 60px 0 30px;}
}
@media (max-width: 991px) { 
    .conversational-ai .video-holder{width: 100%;height: auto; margin-bottom: 15px;}
    .conversational-ai{padding: 50px 0 0 0;}
}