.digital-creation{padding: 170px 0; background-color: #fff;}
.digital-creation .robort-icon-holder{width: 89px;height: 89px;background: #08B08C 0% 0% no-repeat padding-box;border-radius: 12px;position: absolute;top: -40px;right: -25px;}
.digital-creation .video-holder{background: #F5F5F5 0% 0% no-repeat padding-box;border-radius: 15px;padding: 11px;width: 613px;height: 469px; position: relative;}
.digital-creation .video-holder video{width: 100%;height: 100%;overflow: hidden;object-fit: cover;}
.digital-creation .video-icon-holder{width: 217px;height: 195px;padding:39px;background: #272560 0% 0% no-repeat padding-box;border-radius: 12px;position: absolute;bottom: -60px;left: -78px;z-index: 1;}
.digital-creation .video-icon-holder img{width: 100%;height: 100%;overflow: hidden;object-fit: cover;}
.digital-creation .digital-creation-heading{color: #272560;}
.digital-creation-content p{padding-right: 156px;}
/* Media Queries */
@media (max-width : 1600px){
    .digital-creation .video-icon-holder {width: 89px;height: 89px;padding: 16px;bottom: -25px;left: -56px;}
}
@media(max-width:1400px){
    .digital-creation .video-icon-holder{left: 0; bottom: -15px;}
}
@media (max-width: 1399px) { 
    .digital-creation .video-holder{width: 433px; height: 339px;}
    .digital-creation{padding: 100px 0;}
    .digital-creation .robort-icon-holder { width: 70px; height: 70px;}
    .digital-creation .digital-creation-heading{font-size: 30px; line-height: 33px;}
    .digital-creation-content p { padding-right: 66px;}
}
@media (max-width: 1199px) { 
    .digital-creation .video-holder{width: 413px;height: 469px;}
    .digital-creation .video-holder{width: 500px;height: 400px;}
    .digital-creation-content p {padding-right: 0;}
}
@media (max-width: 1020px){
    .digital-creation .video-icon-holder{width: 75px; height: 75px; bottom: -44px; left: -20px;}
}
@media (max-width: 991px) { 
    .digital-creation .video-holder{width: 613px;height: 469px;margin: 0 40px 100px;}
    .digital-creation .video-holder{width: 450px;height: 350px;}
    .award-winning .brand-holder { width: 122px; height: 81px;}
}
@media (max-width : 567px){
    .digital-creation .video-icon-holder {left: -30px;}
    .digital-creation-content p{padding-right: 0px;}
    .digital-creation {padding: 46px 0;}
}
@media (max-width : 575px){
    .digital-creation { padding: 40px 0;}
    .digital-creation .digital-creation-heading{font-size: 27px; line-height: 30px;}
}