.our-customer{padding: 140px 0; background-color: #fff;}
.our-customer .slick-current.slick-active {transform: scale(1.1);opacity: 1;position: relative;z-index: 4;}
.our-customer .slick-slide {color: #FFF;min-height: 422px;display: flex !important;align-items: center;justify-content: center;transform: scale(0.8);transition: all 0.4s ease-in-out;}
.our-customer .slick-slide,
.our-customer .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {transform: scale(0.8, 0.8);transition: all 0.4s ease-in-out;opacity: 0.4;}
.our-customer .review-card{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 6px #36031A29;border-radius: 32px; width: 640px;height: 357px;padding: 50px 56px;}
.our-customer .review-card p{color: #272560;margin-bottom: 60px;}
.our-customer .review-card strong{font: normal normal bold 24px/30px Outfit;letter-spacing: 0px;color: #272560;}
.our-customer .review-card span{font: normal normal normal 24px/30px Outfit;letter-spacing: 0px;color: #AFA8A8;}
.our-customer .review-card .user-dp{width: 72px;height: 72px;border: 2px solid #E20067;object-fit: cover;border-radius: 100%; transition: all ease-in-out 0.3s;}
.our-customer .review-card .user-dp img{width: 100%;height: 100%;object-fit: cover;overflow: hidden;border-radius: 100%;}
.our-customer .slick-prev,.our-customer .slick-next{width: 72px;height: 72px;border: 3px solid #08B08C;border-radius: 100%;z-index: 127;}
.our-customer .slick-next {right: 0px;top: -70px; transition: all ease-in-out 0.3s;}
.our-customer .slick-prev {right: 92px;top: -70px;left: auto;content: ""; transition: all ease-in-out 0.3s;}
.our-customer .slick-prev:hover,.our-customer .slick-next:hover,.our-customer .slick-next:focus.slick-next:hover,.our-customer .slick-prev:focus.slick-prev:hover{background-color: #08B08C; border-color: #fff;z-index: 100;}
.our-customer .slick-prev:focus,.our-customer .slick-next:focus{border: 3px solid #08B08C; border-radius: 50%; background-color: transparent;}
/* .slick-prev:focus:before, .slick-next:focus:before{opacity: 0.75 !important;} */
.our-customer .our-customer-heading{color: #272560;}
.our-customer  .slick-prev:hover:before,.our-customer  .slick-prev:focus:before,.our-customer  .slick-next:hover:before,.our-customer  .slick-next:focus:before
{opacity: 0.75 !important;}
/* Media Queries */
@media(max-width:1400px){
    .our-customer .review-card{width: 531px; height: 361px;}
    .our-customer .review-card p{font-size: 16px; line-height: 25px;}
}
@media(max-width:1399px){
    .our-customer{padding-bottom:0;}  
    .our-customer .slick-slider{padding: 0 0px;}
    .our-customer .review-card{width: 463px; padding: 32px 28px; height: auto;}
    .our-customer .review-card p{font-size: 16px; line-height: 20px; margin-bottom: 30px; font-weight: 400;}
    .our-customer .review-card strong { font: normal normal bold 18px/21px Outfit;}
    .our-customer .review-card span { font: normal normal bold 18px/21px Outfit;}
    .our-customer .slick-prev, .our-customer .slick-next { width: 52px; height: 52px;}
    .our-customer .slick-prev {right: 72px;}
}
@media (max-width: 1199px) { 
    .our-customer{padding-bottom: 50px;}
    .our-customer .slick-prev {right: 72px; top: -45px;}
    .our-customer .slick-next {right: 0; top: -45px;}
    .our-customer .slick-slider {padding-top: 0;}
    .our-customer .review-card{width: 350px; padding: 21px 22px;}
    .our-customer .review-card p{margin: 10px;}
    .our-customer .review-card .user-dp {width: 52px;height: 52px;}
}
/* Media Queries */
@media (max-width: 991px) { 
    .our-customer{padding: 90px 0;}
    .our-customer .slick-slider {padding-top: 10px;}
    .our-customer .review-card{width: 450px;}
    .our-customer .review-card strong {font: normal normal bold 15px/20px Outfit;}
    .our-customer .review-card span {font: normal normal normal 15px/24px Outfit;}
}
@media(max-width:767px){
    .our-customer .review-card { width: 250px;}
    .our-customer .review-card p { font-size: 12px; line-height: 17px;}
}
@media (max-width: 567px) { 
    .our-customer .review-card{width: auto;height: 100%;padding: 15px;margin: 0 12px;}
    .our-customer .slick-slide{min-height: auto;}
}
@media(max-width:575px){
    .our-customer{padding: 30px 0 25px 0;}
    .our-customer .slick-prev, .our-customer .slick-next { width: 42px; height: 42px;}
    .our-customer .slick-prev {right: 57px;}
}