.award-winning{padding: 120px 0; background-color: #fff;}
.award-winning .heading h2{width: 837px;text-align: center;color: #272560;}
.award-winning .heading {margin-bottom: 25px;}
.award-winning .brand-holder{padding: 0 15px; width: 300px;height: 149px;background: #F8F8F8 0% 0% no-repeat padding-box;}


/* Media Queries */
@media(max-width:1699px){
    .award-winning .brand-holder{width: 228px; height: 132px;}
}
@media (max-width: 1600px) { 
    .award-winning .brand-holder{width: 211px;height: 139px;}
}
@media (max-width: 1199px) {
    .award-winning .heading h2{width: 100%;font: normal normal 800 40px/55px Outfit;}
    .award-winning .brand-holder { width: 161px; height: 109px;}
    .award-winning { padding: 50px 0;}
}
@media (max-width: 575px) {
   .award-winning .heading h2 {font: normal normal 800 27px/35px Outfit;}
   .award-winning span{margin-bottom: 5px;}
   .award-winning{padding: 15px 0;}
   .award-winning .heading { margin-bottom: 5px;}
}
@media(max-width:350px){
    .award-winning .heading h2 { font: normal normal 800 19px/23px Outfit;}
}