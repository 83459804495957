#footer{background: #272560 0% 0% no-repeat padding-box;padding:80px 0 0;position: relative;border-top: 7px solid #08B08C;}
#footer .footer-logo{width:100px;}
.home-footer .footer-links-ul{font-size:14px;line-height:18px;}
.home-footer .footer-links-ul li{margin:0 15px 20px;}
.home-footer .footer-links-ul li a{color: #FFFFFF;position: relative;z-index: 2;transition: all ease-in-out 0.3s;}
.home-footer .footer-links-ul li a::after{content: ""; position: absolute; bottom: -5px; height: 4px; width: 0;background-color: #08B08C; left: 50%; transform: translateX(-50%);transition: all ease-in-out 0.3s;}
.home-footer .footer-links-ul li a:hover:after{width: 100%;}
.home-footer .footer-links-ul li a:focus-visible{outline: none; box-shadow: none;}
.home-footer .bg-icon{width:200px;position: absolute;right: 0;bottom: 0;}
.home-footer .bg-icon img{width: 100%;}
.home-footer .socialMedia-icon-holder{width: 40px;height: 40px;background-color: #3b397a;border-radius: 100%;cursor: pointer;color: #fff; transition: all ease-in-out 0.3s;}
.home-footer .socialMedia-icon-holder:hover{background-color: #fff; color: #272560;}
.home-footer .socialMedia-icon-holder:focus-visible{outline: none; box-shadow: none;}
.footer-bottom{border-top:1px solid #5f5f5f;padding:15px 0;}
#footer .footer-bottom p{font-size:14px;line-height:17px;}
@media(max-width:767px){
    #footer{padding:50px 0 0;}
}
@media(max-width:575px){
    #footer{padding:30px 0 0;}
}