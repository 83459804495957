.studio-sidebar {-webkit-box-pack: justify;background-color: #272560;flex-shrink: 0;width: 250px;z-index: 1;overflow: visible;height: 100vh;min-height: 100%;transition: width 0.3s ease-in-out 0s;padding:30px 16px 110px;position: relative;}
.studio-sidebar .logo {width: 55px;height: auto;}
.studio-sidebar .logo img{width: 100%;height: auto;}
.studio-sidebar .main-menu{width:100%;height: calc(100% - 48px);overflow-y: auto;}
.studio-sidebar .menu-button {width:100%;position: relative;display: flex;-webkit-box-align: center;align-items: center;border: none;padding: 0px;outline: none;cursor: pointer;white-space: nowrap;font-weight: 400;font-size: 20px;letter-spacing: -0.02em;color: #d8d8d8;background-color: transparent;overflow: hidden;margin: 0 0 20px;text-decoration: none; transition: all ease-in-out 0.3s;}
.studio-sidebar .menu-button .menu-img {width: 40px;height: 40px;flex-shrink: 0;border-radius: 50%;background-color: transparent;display: flex;-webkit-box-pack: center;justify-content: center;-webkit-box-align: center;align-items: center;transition: transform 0.3s ease-in-out 0s;margin-right: 20px;color: inherit;transition:all ease-in-out 0.3s}
.studio-sidebar .menu-button:hover .menu-img {background-color: #08B08C;color: #fff;}
.studio-sidebar .menu-button.active .menu-img {background-color: #08B08C;color: #fff}
.studio-sidebar .menu-button .menu-tag {max-width: calc(100% - 60px);display: inline-block;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;transition:all ease-in-out 0.3s}
.studio-sidebar .menu-button.active .menu-tag,
.studio-sidebar .menu-button:hover .menu-tag{color:#08B08C;}
.studio-sidebar .side-foot {width: calc(100% - 32px);position: absolute;left: 16px;bottom:0;}
.studio-sidebar .menu-button .guest-tag {width: calc(100% - 30px);display: inline-flex;align-items: center;}
.studio-sidebar .menu-button .arrow {display: block;width: 30px;transition: all ease-in-out 0.3s;}
.studio-sidebar .menu-button.menu-opened .arrow {transform: rotate(180deg);transition: all ease-in-out 0.3s;}
.studio-sidebar .footer-box {visibility: hidden;opacity: 0;position: absolute;pointer-events: all;width: 100%;background:#38393c;border-radius: 8px;padding:5px;font-size: 1.6rem;z-index: 19;transition: opacity 0.1s ease-in-out 0s;box-shadow: black 0px 0px 6px -1px;bottom: 6rem;color:#fff;}
.studio-sidebar .footer-box.menu-opened {visibility: visible;opacity: 1;}
.studio-sidebar .footer-box a {font-size: 18px;color: #fff;text-decoration: none;display: flex;align-items: center; padding: 9px 10px; border-radius: 5px;background:  #08B08C; margin-bottom: 4px; width: 100%;}
.studio-sidebar .footer-box a:hover {background-color: rgba(8, 176, 140, 0.7);}
.studio-sidebar .footer-box a span:last-child{margin-left: 5px;display: inline-block;color:#fff;font-size:16px;line-height:16px;}
/**/
.studio-sidebar .bars {cursor: pointer; position: absolute;right: -30px;top: 25px;width: 30px;height: 30px;color: #000;background: #fff;border-radius: 0px 5px 5px 0px;align-items: center;display: none;}
/* responsive */
@media only screen and (max-width:1399px){
    .studio-sidebar {width: 200px;}
    .studio-sidebar .footer-box a{font-size: 13px; line-height: 16px;}
    .studio-sidebar .footer-box a span:last-child{font-size: 13px; line-height: 16px;}
    .studio-sidebar .menu-button .menu-img {width: 24px;height: 24px;margin-right: 7px;}
    .studio-sidebar .menu-button .menu-tag {max-width: calc(100% - 31px);}
    .studio-sidebar .main-menu {width: 100%;}.studio-sidebar .menu-button {font-size: 16px;}
}
@media only screen and (max-width:1199px){
    .studio-sidebar {width: 200px;position: absolute;z-index: 999;left: -200px;transition: all ease-in-out 0.3s;}
    .studio-sidebar.show {left: 0px;transition: all ease-in-out 0.3s;}
    .studio-sidebar .bars{display: flex;}
}