.shared-stories{background-image: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697108074/d-id-front/studio-bg_qqbvs3.png");background-size: cover;background-repeat: no-repeat;padding: 41px 0 27px;}
.shared-stories h2>strong{font: normal normal 600 32px/38px Outfit;letter-spacing: 0px;color: #08B08C;text-align: center;}
.shared-stories h2{color: #FFFFFF;}
.shared-stories h2 span{color: #FFFFFF;}
.shared-stories .heading{margin-bottom: 30px;}
.shared-stories p{padding: 0 12px;color: #FEFEFE;text-align: center;width: 1029px;}
.shared-stories .count-holder{width: 167px;height: 102px;margin-right: 29px;margin-bottom: 12px;background: #FFFFFF 0% 0% no-repeat padding-box;border: 1px solid #707070;border-radius: 8px;}
.shared-stories .count-holder .count-value{font: normal normal 800 85px/88px Outfit;letter-spacing: 0px;color: #08B08C;}
.shared-stories span.created-since{font: normal normal 300 18px/40px Outfit;letter-spacing: 0px;color: #FEFEFE;text-transform: capitalize;}
.shared-stories span.year{font-weight: 700; font-size: 18px; line-height: 21px; }
/* Media Queries */
@media(max-width:1399px){
  .shared-stories .count-holder{width: 127px; height: 72px; margin-right: 15px;}
  .shared-stories .count-holder .count-value { font: normal normal 800 62px/65px Outfit;}
  .shared-stories span.created-since { font: normal normal 300 13px/17px Outfit;} 
  .shared-stories span.year{font-size: 13px; line-height: 17px;}
  .shared-stories .heading {margin-bottom: 5px;}
}
@media (max-width: 1199px) { 
   .shared-stories p{width: 100%;} 
   .shared-stories .count-holder .count-value{font: normal normal 800 40px/55px Outfit;}
   .shared-stories .count-holder {width: 143px;height: 89px;}
  .shared-stories .heading { margin-bottom: 5px;}}
@media(max-width:497px){
  .shared-stories .count-holder { width: 93px; height: 60px; margin-right: 5px;}
  .shared-stories p { padding: 0px 0px;}
  .shared-stories .heading { margin-bottom: 0px;}
}
@media(max-width:347px){
  .shared-stories .count-holder { width: 68px; height: 56px;}
  .shared-stories .count-holder .count-value { font: normal normal 800 30px/33px Outfit;}
}